.Count {
    padding: 2rem;
    margin: 1rem;

    border-radius: 2em;
    box-shadow: 1px 2px 2px #0D3B66;
    background-color: #FAF0CA;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #0D3B66;
}