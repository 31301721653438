/* write your css here */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --border-radius: 8px;
}

html,body{
    overflow-x: hidden;
}
*{
    box-sizing: border-box;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 80px;
    aspect-ratio: 1;
    border: 10px solid #000;
    box-sizing: border-box;
    background: 
      radial-gradient(farthest-side,#fff 98%,#0000) top,
      radial-gradient(farthest-side,#fff 98%,#0000) top,
      radial-gradient(farthest-side,#fff 98%,#0000) left,
      radial-gradient(farthest-side,#fff 98%,#0000) right,
      radial-gradient(farthest-side,#fff 98%,#0000) bottom,
      #000;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    filter: blur(4px) contrast(10); 
    animation: l19 2s infinite;
  }
  @keyframes l19 {
    0%   {background-position:top,top,left,right,bottom}
    25%  {background-position:right,top,left,right,bottom}
    50%  {background-position:bottom,top,left,right,bottom}
    75%  {background-position:left,top,left,right,bottom}
    100% {background-position:top,top,left,right,bottom}
  }

.grad-blue{
    background: linear-gradient(118deg, #1FA2FF -3.62%, #12D8FA 50.44%, #A6FFCB 104.51%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.grad-orange{
    background: linear-gradient(118deg, #FF512F -4.8%, #F09819 107.46%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.grad-yellow{
    background: linear-gradient(118deg, #E65C00 -6.05%, #F9D423 106.11%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.grad-red{
    background: linear-gradient(118deg, #833AB4 -2.4%, #FD1D1D 52.25%, #FCB045 106.89%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.code-block{
    border: 1px solid rgba(255, 255, 255, 0.22);
    background: linear-gradient(112deg, rgba(14, 26, 45, 0.24) -1.4%, rgba(17, 30, 50, 0.38) 104.96%);
    backdrop-filter: blur(26px);
}

.bg_homepage{
    background: url('../src/assets/Images/bghome.svg');
}

.flexBox{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.select-card{
    background-color: rgba(241, 242, 255, 1);
    box-shadow: 5px 5px 0px 0px#FFD60A;
}

.select-heading{
    color: black;
}

.select-subheading{
    color: #585D69;
}

.select-tag{
    border-top: solid 1px #C5C7D4;
    color: #0A5A72;
}

sup{
    color: red;
}

.nav-link{
  border: 1px solid transparent;
  padding: .2rem .7rem;
  padding-left: 0rem;
}

.active .nav-link{
    color: #F1F2FF;
    border: 1px solid #F1F2FF;
    padding: .2rem 1rem;
    border-radius: 1rem;
    background-color: #161D29;
}

::-webkit-scrollbar{
    background-color: #000814;
    width: 0.5vw;
}
::-webkit-scrollbar-thumb{
    background-color: #2C333F;
    border: 1px solid white;
    border-radius: 10%;
}

.profile::-webkit-scrollbar{
    background-color: #000814;
    width: 0.2vw;
}

.video::-webkit-scrollbar{
    background-color: transparent;
    width: 0.2vw;
}

.profile::-webkit-scrollbar-thumb{
    background-color: #AFB2BF;
    border: 1px solid #AFB2BF;
    border-radius: 10%;
    visibility: hidden;    
}

.profile:hover.profile::-webkit-scrollbar-thumb{
    visibility: visible;
}

.dashed{
    position: relative;
    height: 14px;
    overflow: hidden;
}

.dashed::after{
    content: "------------------------------------------------------------";
    letter-spacing: 5px;
    font-weight: bold;
    left: 0;
    right: 0;
}

@media screen and (max-width:640px){
    ::-webkit-scrollbar-thumb{
        display: none;
    }
}


.card {
    max-width: 400px;
    width: 100%;
    height: 100%;
    padding: .2rem;
    overflow: hidden;
    /* margin: 100px auto; */
    border-radius: var(--border-radius);
  }
  
  .card .inner {
    height: 100%;
    padding: 25px;
    color: #fff;
    border-radius: var(--border-radius);
  }

  .example-3 {
    position: relative;
    border-radius: var(--border-radius);
    padding: 4px;
  }
  
  .example-3 .inner {
    border-radius: 4px;
  }
  
  .example-3::before,
  .example-3::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
      45deg,
      #ff595e,
      #ffca3a,
      #8ac926,
      #1982c4,
      #6a4c93,
      #ff6700
    );
    background-size: 400%;
    z-index: -1;
    animation: glow 20s linear infinite;
    width: 100%;
    border-radius: var(--border-radius);
  }
  
  .example-3::after {
    filter: blur(25px);
    transform: translate3d(0, 0, 0);
  }
  
  @keyframes glow {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 100% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }
  
  @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400');
.text-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: baseline;
  column-gap: 1rem;
  /* color:#fff; */
  font-family:'Roboto';
  font-size:2rem;
  font-weight:100;
  overflow:hidden;
  animation:bg 5s linear infinite;
}

.dropping-texts {
  display: inline-block;
  width: 180px;
  text-align: left;
  height: 36px;
  vertical-align: -2px;
}

.dropping-texts > div {
  font-size:0px;
  opacity:0;
  margin-left:-30px;
  position:absolute;
  font-weight:500;   
  box-shadow: 0px 60px 25px -20px rgba(0,0,0,0.5);
}

.dropping-texts > div:nth-child(1) {
  animation: roll 5s linear infinite 0s;
}
.dropping-texts > div:nth-child(2) {
  animation: roll 5s linear infinite 1s;
}
.dropping-texts > div:nth-child(3) {
  animation: roll 5s linear infinite 2s;
}
.dropping-texts > div:nth-child(4) {
  animation: roll2 5s linear infinite 3s;
}

@keyframes roll {
  0% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top:0px;
    transform: rotate(-25deg);
  }
  3% {
    opacity:1;
    transform: rotate(0deg);
  }
  5% {
    font-size:inherit;
    opacity:1;
    margin-left:0px;
    margin-top:0px;
  }
  20% {
    font-size:inherit;
    opacity:1;
    margin-left:0px;
    margin-top:0px;
    transform: rotate(0deg);
  }
  27% {
    font-size:0px;
    opacity:0.5;
    margin-left:20px;
    margin-top:100px;
  }
  100% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top:0px;
    transform: rotate(15deg);
  }
}

@keyframes roll2 {
  0% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top:0px;
    transform: rotate(-25deg);
  }
  3% {
    opacity:1;
    transform: rotate(0deg);
  }
  5% {
    font-size:inherit;
    opacity:1;
    margin-left:0px;
    margin-top:0px;
  }
  30% {
    font-size:inherit;
    opacity:1;
    margin-left:0px;
    margin-top:0px;
    transform: rotate(0deg);
  }
  37% {
    font-size:2rem;
    opacity:0;
    margin-left:-100px;
    margin-top:-150px;
  }
  100% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top:0px;
    transform: rotate(15deg);
  }
}


.footer2-outer-container {
  margin-top: 5rem;
  background: #161D29;
  /* border-top: 1px solid rgb(75, 75, 75); */
  color: white;
}

.footer2-inner-container {
  display: flex;
  max-width: 1400px;
  margin: 0px auto;
  padding: 3rem 2rem;
  gap: 20px;
  justify-content: space-around;
}

.footer2-inner-container a {
  color: rgb(218, 218, 218);
}

.footer2-logo {
  height: 4vw;
  margin-top: 1rem;
}

.footer2-section {
  background: #000814;
  padding: 15px;
  border-radius: 10px;
  transition: 0.4s;
  min-width: 250px;
  border: 1px solid transparent;
  display: flex;
  column-gap: 2rem;
}

.footer2-section:hover {
  background-color: #000814a0;
  border: 1px solid #C5C7D4;

}

.footer-link:hover {
  color: #C5C7D4;
}

.footer2-inners {
  display: flex;
  justify-content: end;
  gap: 1rem;
}

.footer2-blogs {
  flex-grow: 1;
  max-width: 600px;
}

.footer2-heading {
  color: #FFD60A;
  margin-bottom: 6px;
  font-size: max(1.2vw, 18px);
}

.footer2-blogs {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
}

.footer2-blog-card {
  background: rgb(0, 8, 20);
  padding: 10px;
  transition: 0.4s;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid transparent;
}

.footer2-blog-card:hover {
  background-color: rgba(0, 8, 20,.4);
  border: 1px solid #C5C7D4;
}

/* .animated-border {
  transition: 0.3s all;
  position: relative;
  display: inline-block;
  border: none;
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
  z-index: 1;
  transition: 0.4s;
}

.animated-border::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #c9f31d, black, #c9f31d, black);
  background-size: 400%;
  border-radius: 5px;
  z-index: -1;
  animation: borderAnimation 14s linear infinite;
  opacity: 0.9;
  padding: 0.2px;
  box-sizing: border-box;
}

.animated-border:hover::before {
  opacity: 1;
}

.animated-border::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: rgb(24, 24, 24);
  border-radius: 4px;
  z-index: -1;
  transition: 0.3s all;
}

.animated-border:hover::after {
  background-color: rgb(36, 36, 36);
}


.animated-border:hover {
  box-shadow: rgba(34, 255, 5, 0.25) 0px 13px 27px -5px, rgba(120, 196, 50, 0.3) 0px 8px 16px -8px;
}

@keyframes borderAnimation {
  0% {
      background-position: 0%;
  }

  100% {
      background-position: 400%;
  }
} */



@media (max-width: 800px) {
  .footer2-inner-container {
      flex-direction: column;
      align-items: center;
  }

  .footer2-logo {
      width: 200px;
      height: auto;
      margin-bottom: 30px;
  }

  .footer2-inners {
      flex-direction: column;
  }

  .footer2-blog-card p {
      font-size: 14px;
  }
}